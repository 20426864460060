import React from 'react'
import { InPageInsights } from '../../../../components/InPageInsights'
import { useDataContext } from './DataProvider'

export const Insights = () => {
  const { insights } = useDataContext()

  if (!insights || insights.length === 0) {
    return null
  }

  return <InPageInsights insights={insights} />
}

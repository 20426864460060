import React from 'react'
import RecycledRateSchema from '../../images/HowIsThisCalculated/recyclingRateSchema.png'

export const KpiRecyclingRateContent = () => (
  <>
    <p className="IntroParagraph">
      Ingka Group handles, sells and manages a lot of products and materials. Sometimes these products lose their
      commercial value and/or their functionality and become potential waste. The reasons for that could be damages,
      leftover food or quality incompliance. Additionally, there is waste related to consumables, packaging material,
      tenants, waste produced by visitors and construction.
    </p>
    <p>
      Ingka Group strives for continuous improvement in preventing, reducing and managing waste following the waste
      hierarchy.{' '}
    </p>
    <img src={RecycledRateSchema} alt="Electricity calculation example table" />
    <p>When we cannot prevent or reduce waste, we strive to recycle 100% of our waste by FY30. </p>
    <h3>How do we calculate recycling rate?</h3>
    <p>We calculate our recycling rate by using following formula: </p>

    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
      <span>Recycled Rate =</span>
      <table style={{ display: 'inline-block', textAlign: 'center', marginLeft: '10px' }}>
        <tbody>
          <tr>
            <td>Recycled Waste</td>
          </tr>
          <tr>
            <td style={{ borderTop: '2px solid black' }}>Total Waste</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p>
      Recycled waste is all the waste coming from our own operations<sup>1</sup> which is recycled, including waste
      processed via an aerobic (e.g. biogas) or anaerobic (e.g. composting) treatment.{' '}
    </p>

    <p style={{ fontSize: '12px' }}>
      1 Own operations: Own operations included all producers (operations, food, mixed, quality, construction, tenants)
      apart from waste from “External/Customer”.{' '}
    </p>

    <h3>Where is the data coming from?</h3>
    <p>
      The amount of waste and how it is processed is provided by Facility Management through Sustain, a platform used to
      collect and manage data, goals and actions related to greenhouse gas emissions, energy, water, waste and
      refrigerants from our global operations. Data related to waste is extracted monthly. At the end of each month,
      this dashboard is updated with data for the previous month.
    </p>
    <h3>How can we improve our recycling rate?</h3>
    <p>We can improve our recycling rate by:</p>
    <ul>
      <li>Reducing amounts of hard-to-recycle fractions</li>
      <li>TSegregating waste as much as possible</li>
      <li>Finding strategic waste management partners for off-site waste handling and treatment</li>
      <li>
        Understanding the composition of our waste and needed improvements by conducting{' '}
        <a
          href={
            'https://iweof.sharepoint.com/sites/about/f/igf/gre/Shared%20Documents/Facility%20Management/Instruction_Waste%20Management_Working%20with%20Picking%20tests_November%202024.pdf?web=1'
          }
          style={{ textDecoration: 'underline' }}
        >
          picking tests
        </a>
      </li>
    </ul>
  </>
)

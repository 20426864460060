import React from 'react'
import { WasteRecyclingRateGraph } from '../../../../components/ZeroWasteGraph'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { MainCard } from '../../../../components/KPIPage'
import { useDataContext } from './DataProvider'
import colours from '../../../../Colours.module.scss'
import { useDataAvailabilityContext, useLocations } from '../../../../context'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'
import { Serie } from '../../../../components/BaseGraphs/ChartContainer'
import { formatRelativeNumber } from '../../../../components/Utils/format'
import { groupBy } from 'lodash'
import { getLocationId, isSiteId } from '../../../../components/Utils/utils'

export const MainChart = () => {
  const { wasteData, domain, goals, performance } = useDataContext()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation } = useLocations()

  const currentFY = dataAvailability?.recyclingCurrentFY ?? getIngkaFinancialYear(new Date()).getFullYear()

  const series: Serie[] | undefined = React.useMemo(() => {
    if (!wasteData) return undefined

    const locationId = getLocationId(currentLocation)

    const currLocationRecyclingRateGoal = isSiteId(locationId)
      ? goals?.sites.find(x => x.siteId === locationId)
      : goals?.countries.find(x => x.countryCode === locationId)

    return [
      {
        name: `FY${currentFY - 2000}`,
        data: (wasteData || []).map((item, index) => {
          const date = domain[index]
          return { x: new Date(date), y: item.currentRecyclingRate }
        }),
        color: colours.purple1,
        unit: '%'
      },
      {
        name: `FY${currentFY - 2001}`,
        data: (wasteData || []).map((item, index) => {
          const date = domain[index]
          return { x: new Date(date), y: item.previousRecyclingRate }
        }),
        color: colours.offWhite1,
        fill: colours.grey1,
        unit: '%'
      },
      {
        color: '#A5B4D9',
        data: Object.values(groupBy(domain, d => getIngkaFinancialYear(d).getFullYear()))[0]?.map(date => ({
          x: date,
          y: currLocationRecyclingRateGoal ? currLocationRecyclingRateGoal?.recyclingRateGoal : null
        })),
        name: `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} Goal`,
        zIndex: 0,
        unit: '%'
      }
    ] as Serie[]
  }, [wasteData, currentFY, domain, goals, currentLocation, dataAvailability])

  const subTitle = React.useMemo(() => {
    return (
      <div>
        <span className="Amount">
          {performance?.recycling_rate ? formatRelativeNumber(performance.recycling_rate) : ''}
        </span>
        <span className="Label"> % YTD</span>
      </div>
    )
  }, [performance])

  return (
    <MainCard title="Recycling Rate" subtitle={subTitle}>
      <div className="GraphContainer">
        {domain ? <WasteRecyclingRateGraph dates={domain} data={series} dateFormat="month" /> : <LoadingSkeleton />}
      </div>
    </MainCard>
  )
}

import React from 'react'
import { DataProvider } from './DataProvider'
import { PageHead } from './PageHead'
import { BenchmarkBlock } from './BenchmarkBlock'
import { PerformanceBlock } from './PerformanceBlock'
import { MainChart } from './MainChart'
import { CardRow } from '../../../../components/KPIPage'
import { SmallKPIs } from './SmallKPIs'
import { BenchmarkDetails } from './BenchmarkDetails'
import { Insights } from './Insights'

export const RecyclingRateKPIPage = () => {
  return (
    <DataProvider>
      <PageHead>
        <CardRow className="BenchmarkingAndGoals">
          <BenchmarkBlock key="benchmarking" />
          <PerformanceBlock key="goals" />
        </CardRow>
        <MainChart />
        <SmallKPIs />
        <Insights />
      </PageHead>
      <BenchmarkDetails />
    </DataProvider>
  )
}

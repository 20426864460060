import React from 'react'
import { BenchmarkingModal } from '../../../../components/KPIPage'
import { useDataContext } from './DataProvider'
import { getGoalsForLocation, getLocationId, getLocationLabel, isSiteId } from '../../../../components/Utils/utils'
import { useDataAvailabilityContext, useLocations } from '../../../../context'
import { renderRecyclingRateBenchmark } from '../utils/benchmarking'
import { mergeBenchmarkWithGoal } from '../utils/mappers'

export const BenchmarkDetails = () => {
  const { benchmarking, benchmarkModalOpen, setBenchmarkModalOpen, goals } = useDataContext()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation } = useLocations()
  const locationId = getLocationId(currentLocation)

  const goalsFy = dataAvailability?.planetCurrentFY ?? 2024

  const selectedLocationBenchmark = React.useMemo(
    () => benchmarking?.find(b => b.id === locationId),
    [benchmarking, locationId]
  )

  const benchmarksWithoutCurrentLocation = React.useMemo(() => {
    const isSite = isSiteId(locationId)
    return (
      mergeBenchmarkWithGoal(benchmarking, goals)?.filter(
        b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)
      ) ?? []
    )
  }, [benchmarking, goals, locationId])

  const footerBenchmark = {
    id: locationId,
    label: getLocationLabel(currentLocation),
    selectable: true,
    previousYtd: selectedLocationBenchmark?.previousYtd ?? 0,
    currentYtd: selectedLocationBenchmark?.currentYtd ?? 0,
    previousFy: selectedLocationBenchmark?.previousFy ?? 0,
    rolling: 0,
    goal: getGoalsForLocation(locationId, goals)?.recyclingRateGoal ?? 0,
    goalNextFY: getGoalsForLocation(locationId, goals)?.recyclingRateGoalNextFY ?? 0,
    goalPrevFY: getGoalsForLocation(locationId, goals)?.recyclingRateGoalPrevFY ?? 0
  }

  const currFyHeading = `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} YTD`
  const prevFyResultHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} Result`
  const goalHeading = `FY${goalsFy - 2000} Goal`
  const goalHeadingNext = `FY${goalsFy + 1 - 2000} Goal`
  const goalHeadingPrev = `FY${goalsFy - 1 - 2000} Goal`

  return (
    <BenchmarkingModal
      benchmarks={benchmarksWithoutCurrentLocation.filter(x => x.id !== locationId)}
      closeFn={() => setBenchmarkModalOpen(false)}
      footerBenchmark={footerBenchmark}
      headers={[
        [
          { name: prevFyHeading, key: 'previousYtd' },
          { name: prevFyResultHeading, key: 'previousFy' }
        ],
        [{ name: currFyHeading, key: 'currentYtd' }],
        [{ name: goalHeadingPrev, key: 'goalPrevFY' }],
        [{ name: goalHeading, key: 'goal' }],
        [{ name: goalHeadingNext, key: 'goalNextFY' }]
      ]}
      isOpen={benchmarkModalOpen && Boolean(benchmarking?.length)}
      locationId={locationId}
      renderBenchmark={renderRecyclingRateBenchmark}
      sortBy="label"
      sortDirection="asc"
      title="% of waste being recycled"
    />
  )
}

import React from 'react'
import { Benchmarking } from '../../../../components/KPIPage'
import { getLocationId, isSiteId } from '../../../../components/Utils/utils'
import { mergeBenchmarkWithGoal } from '../utils/mappers'
import { useDataContext } from './DataProvider'
import { useDataAvailabilityContext, useLocations } from '../../../../context'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'
import { renderRecyclingRateBenchmark } from '../utils/benchmarking'

export const BenchmarkBlock = () => {
  const { benchmarking, goals, setBenchmarkModalOpen } = useDataContext()
  const { currentLocation } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const locationId = getLocationId(currentLocation)

  // Get fiscal year from data availability or current year
  const goalsFy = dataAvailability?.recyclingCurrentFY ?? getIngkaFinancialYear(new Date()).getFullYear()

  const currFyHeading = `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} YTD`
  const prevFyResultHeading = `FY${(dataAvailability?.planetPreviousFY ?? 2000) - 2000} Result`
  const goalHeading = `FY${goalsFy - 2000} Goal`
  const goalHeadingNext = `FY${goalsFy + 1 - 2000} Goal`
  const goalHeadingPrev = `FY${goalsFy - 1 - 2000} Goal`

  const [selectedLocationBenchmark, comparisonBenchmarks] = React.useMemo(() => {
    const locationId = getLocationId(currentLocation)
    if (benchmarking === undefined) {
      return [undefined, []]
    }
    const benchmarks = mergeBenchmarkWithGoal(benchmarking, goals)

    const selectedLocationBenchmark = benchmarks.find(b => b.id === locationId)

    return [
      selectedLocationBenchmark,
      benchmarks.filter(b => b.id !== locationId).slice(0, selectedLocationBenchmark ? 3 : 4)
    ]
  }, [benchmarking, goals, currentLocation])

  const benchmarksWithoutCurrentLocation = React.useMemo(() => {
    const isSite = isSiteId(locationId)
    return benchmarking?.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []
  }, [benchmarking])

  return (
    <Benchmarking
      benchmarks={
        benchmarking ? [...(selectedLocationBenchmark ? [selectedLocationBenchmark] : []), ...comparisonBenchmarks] : []
      }
      label=" % of waste being recycled"
      headers={[
        [
          { name: prevFyHeading, key: 'previousYtd' },
          { name: prevFyResultHeading, key: 'previousFy' }
        ],
        [{ name: currFyHeading, key: 'currentYtd' }],
        [{ name: goalHeadingPrev, key: 'goalPrevFY' }],
        [{ name: goalHeading, key: 'goal' }],
        [{ name: goalHeadingNext, key: 'goalNextFY' }]
      ]}
      locationId={locationId}
      renderBenchmark={renderRecyclingRateBenchmark}
      openModal={() => setBenchmarkModalOpen(true)}
      totalLocations={benchmarksWithoutCurrentLocation.length}
    />
  )
}

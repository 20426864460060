import React from 'react'
import classNames from 'classnames'
import { isNil } from 'lodash'

interface ResultNumberProps {
  value: number | null
  className?: string
  minimumDigits?: number
  formatNumber?: (number: number) => string | 0
}

function formatResultNumberValue(
  value: number | null,
  minimumDigits: number,
  formatNumber?: (number: number) => string | 0
): string | 0 {
  return isNil(value) || isNaN(value) || !isFinite(value) || value === 0
    ? 'N/A'
    : formatNumber
    ? formatNumber(value)
    : value.toFixed(Math.max(value < 10 ? 1 : 0, minimumDigits))
}

export const ResultNumber: React.FC<ResultNumberProps> = ({ className, value, minimumDigits = 0, formatNumber }) => (
  <div className={classNames('ResultNumber', className)}>
    {formatResultNumberValue(value, minimumDigits, formatNumber)}
  </div>
)

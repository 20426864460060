import React from 'react'
import { ApiGeneratedByLocation, ApiNonRecycledAndGeneratedByType } from '../../../../../api/src/common-types'
import { TimeRange } from '../../ExplorePage'
import { ExploreGraphCard } from '../../ExploreGraphCard'
import { GraphUnit } from '../../../../components/UnitSelector'
import { formatAbsoluteNumber } from '../../../../components/Utils/format'
import { sumBy } from 'lodash'
import { PlanetWasteByLocationGraph } from '../../../../components/PlanetWasteGraphs'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { NonRecycledWasteGraphs } from '../../../../components/ZeroWasteGraph'

interface RecycledWasteGraphsProps {
  locationId: string
  timeRange: TimeRange
  dates?: Date[]
  nonRecyclingWaste?: ApiNonRecycledAndGeneratedByType[]
  mergedGeneratedByLocationWaste?: ApiGeneratedByLocation[]
  generatedByTypeWaste?: ApiNonRecycledAndGeneratedByType[]
}

export const RecycledWasteGraphs: React.FC<RecycledWasteGraphsProps> = ({
  locationId,
  timeRange,
  dates,
  nonRecyclingWaste,
  mergedGeneratedByLocationWaste,
  generatedByTypeWaste
}) => {
  const [selectedUnitByLocation, setSelectedUnitByLocation] = React.useState<string>('kg')
  const [selectedUnit, setSelectedUnit] = React.useState<string>('tonnes')

  const currentUnitByLocation = (unit: string) => setSelectedUnitByLocation(unit)
  const currentUnit = (unit: string) => setSelectedUnit(unit)

  return (
    <>
      <ExploreGraphCard
        heading="Waste Generation by Location"
        amount={
          mergedGeneratedByLocationWaste && selectedUnitByLocation === GraphUnit.RawWasteKg
            ? formatAbsoluteNumber(sumBy(generatedByTypeWaste, 'footprint') * 1000)
            : ''
        }
        unit={selectedUnitByLocation === GraphUnit.RawWasteKg ? 'kg CO2e total' : ''}
      >
        {mergedGeneratedByLocationWaste ? (
          <PlanetWasteByLocationGraph
            siteOrCountry={locationId}
            dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
            selactedUnitBylocations={currentUnitByLocation}
            mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
            timeRange={timeRange}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Non-recycled waste"
        amount={
          nonRecyclingWaste
            ? selectedUnit === 'tonnes'
              ? formatAbsoluteNumber(Math.round(sumBy(nonRecyclingWaste, 'waste')))
              : formatAbsoluteNumber(Math.round(sumBy(nonRecyclingWaste, 'footprint')))
            : 'N/A'
        }
        unit={selectedUnitByLocation}
      >
        {nonRecyclingWaste ? (
          <NonRecycledWasteGraphs
            dates={dates}
            timeRange={timeRange}
            selectedUnit={currentUnit}
            nonRecyclingWaste={nonRecyclingWaste}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
    </>
  )
}

import { RenderBenchmarkType } from '../../../../components/KPIPage'
import { RecyclingRateBenchmark } from '../../../../../api/src/types/benchmark'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import { formatRelativeNumber } from '../../../../components/Utils/format'

export const renderRecyclingRateBenchmark: RenderBenchmarkType<RecyclingRateBenchmark> = (benchmark, keys, classes) => (
  <Fragment key={benchmark.id}>
    <div className={classNames('FirstItem', classes)}>
      {benchmark.label === 'Global' ? 'Global (All Locations)' : benchmark.label}
    </div>
    <div className={classNames('Right', classes, 'Black')}>
      {typeof benchmark[keys[0]] === 'number' ? formatRelativeNumber(Number(benchmark[keys[0]])) : 'N/A'}
    </div>
    <div
      className={classNames(
        'Right',
        classes,
        benchmark.goal && benchmark.currentYtd ? (benchmark.currentYtd >= benchmark.goal ? 'OnTrack' : 'YTD') : 'Black'
      )}
    >
      {formatRelativeNumber(benchmark.currentYtd ?? 0)}
    </div>

    <div className={classNames('Right', classes)}>
      {benchmark.goalPrevFY ? formatRelativeNumber(benchmark.goalPrevFY) : 'N/A'}
    </div>
    <div className={classNames('Right', classes)}>{benchmark.goal ? formatRelativeNumber(benchmark.goal) : 'N/A'}</div>
    <div className={classNames('Right', classes)}>
      {benchmark.goalNextFY ? formatRelativeNumber(benchmark.goalNextFY) : 'N/A'}
    </div>
    <div />
  </Fragment>
)

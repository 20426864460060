import React, { useEffect } from 'react'
import { getRecyclingRateTreatmentWaste, WasteSelector } from '../lib/APIClient'

import colours from '../Colours.module.scss'
import { colorScheme } from './BaseGraphs/Universal'
import { GraphUnit, UnitSelector } from './UnitSelector'
import { ApiGeneratedByLocationAndLandfill, ApiTreatmentWaste } from '../../api/src/common-types'
import { DateFormat, lineChart } from './BaseGraphs/GraphUtil'
import { chain, isEqual, uniq } from 'lodash'
import { useSharedSelections } from '../SharedSelections'
import { getFinancialYearTimeRange } from './Utils/dates'
import { LanguageContext, useDataAvailabilityContext, useLocations } from '../context'
import { NoDataView } from './BaseGraphs/NoDataView'
import { getLocationOrDefault, isCluster } from './Utils/utils'
import { ChartContainer, Serie } from './BaseGraphs/ChartContainer'
import { TimeRange } from '../pages/ExplorePages/ExplorePage'
import { InStoreI18n, Localisation } from '../Localisation'

export const wasteDisposalColorMap: Record<string, string> = {
  'Material recycling': colours.lightBlue3,
  Landfill: colours.lightPink2,
  'Incineration with energy recovery': colours.green1,
  'External sorting facility': colours.orange,
  Compost: colours.blue5,
  Biogas: colours.yellow3,
  'IKEA Circular flows': colours.lightBlue4,
  '(no-value)': colours.lightGreen,
  'Incineration without energy recovery': colours.offWhite7
}
export const recycledDisposalTypes = [
  'aerobic-digestion',
  'anaerobic-digestion',
  'ikea-circular-flows',
  'material-recycling'
]

export async function fetchWasteData(selector: WasteSelector): Promise<ApiTreatmentWaste[]> {
  const response = await getRecyclingRateTreatmentWaste(selector)
  return response
}

interface GroupedData {
  [key: string]: {
    name: string
    description?: string
    data: { x: Date; y: number }[]
    color: string
    zIndex: number
    unit: string
  }
}

type GroupedDataWithId = {
  [key: string]: GroupedData[string] & { id: string }
}

type DataKeyType = 'wasteType' | 'disposalTypeDescription' | 'siteId' | 'countryCode'
interface PlanetWasteBaseGraphProps {
  data?: ApiTreatmentWaste[]
  dataKey: DataKeyType
  dateFormat: DateFormat
  colors: Record<string, string>
  siteOrCountry: string
  selactedUnit?: (unit: string) => void
  selactedUnitBylocations?: (unit: string) => void
  mergedGeneratedByLocationWaste?: ApiTreatmentWaste[] | ApiGeneratedByLocationAndLandfill[]
  isBytype?: boolean
  timeRange?: TimeRange
  isClimatExplore?: boolean
  isClimatExploreRecyclingRate?: boolean
}

const PlanetWasteBaseGraph: React.FC<PlanetWasteBaseGraphProps> = props => {
  const { data, dataKey, mergedGeneratedByLocationWaste, isBytype, timeRange, colors } = props
  const [{ func, wasteUnit, rangeFrom, rangeTo, wasteUnitBylocations }, updateSharedSelections] = useSharedSelections()
  const { locations } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const setWasteUnit = React.useCallback(
    updatedUnit => updateSharedSelections({ wasteUnit: updatedUnit }),
    [updateSharedSelections]
  )
  const setWasteUnitBylocations = React.useCallback(
    updatedUnit => updateSharedSelections({ wasteUnitBylocations: updatedUnit }),
    [updateSharedSelections]
  )

  const additionalComponent =
    props.isClimatExplore ||
    props.isClimatExploreRecyclingRate ||
    !(dataKey === 'countryCode' || dataKey === 'siteId') ? null : (
      <UnitSelector
        enabled
        value={props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit}
        options={[GraphUnit.RawWasteKg, GraphUnit.RecycledWaste, GraphUnit.Landfill]}
        onChange={val => {
          props.selactedUnitBylocations ? setWasteUnitBylocations(val) : setWasteUnit(val)
        }}
        menuPlacement="top"
      />
    )

  const unitText =
    (props.isClimatExplore && dataKey === 'disposalTypeDescription') ||
    (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RawWasteKg
      ? 'kg'
      : (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === GraphUnit.RecycledWaste
      ? 'Recycling %'
      : 'Landfill %'

  useEffect(() => {
    if (props.selactedUnit || props.selactedUnitBylocations) {
      props.selactedUnitBylocations
        ? props.selactedUnitBylocations && props.selactedUnitBylocations(wasteUnitBylocations)
        : props.selactedUnit && props.selactedUnit(wasteUnit)
    }
  }, [wasteUnit, wasteUnitBylocations])

  const isMonthly = timeRange === 'monthly'

  const locationId = getLocationOrDefault()
  const lang = React.useContext(LanguageContext)
  const unit = props.isClimatExploreRecyclingRate
    ? GraphUnit.RecycledWaste
    : props.selactedUnitBylocations
    ? wasteUnitBylocations
    : wasteUnit

  const transformArraydByLocation = (data: ApiGeneratedByLocationAndLandfill[] | undefined) => {
    const groupedData =
      data?.reduce((acc, item, i) => {
        const id = locationId === 'ALL' ? item.countryCode : item.siteId
        if (!acc[id]) {
          acc[id] = {
            id,
            name: locationId === 'ALL' ? item.country_name : item.site_code,
            data: [],
            color: colors[id],
            zIndex: i,
            unit: (props.selactedUnitBylocations ? wasteUnitBylocations : wasteUnit) === 'rawwastekg' ? '' : '%'
          }
        }
        acc[id].data.push({
          x: isMonthly
            ? new Date(item.readable_date)
            : new Date(`${1999 + parseInt(item.fiscal_year)}-09-30T00:00:00.000Z`),
          y: getWasteValue(unit, item)
        })
        return acc
      }, {} as GroupedDataWithId) || {}

    return Object.values(groupedData)
  }

  const transformArrayByType = (data: ApiTreatmentWaste[] | undefined) => {
    const groupedData =
      data?.reduce<GroupedData>((acc, item, i) => {
        const name = item.description
        if (!acc[name]) {
          acc[name] = {
            name: disposalText(name, InStoreI18n[lang]),
            description: name,
            data: [],
            color: colors[name],
            zIndex: i,
            unit: ''
          }
        }
        acc[name].data.push({
          x: isMonthly
            ? new Date(item.readable_date)
            : new Date(`${1999 + parseInt(item.fiscal_year)}-09-30T00:00:00.000Z`),
          y: item.total
        })
        return acc
      }, {} as GroupedData) || {}

    return Object.values(groupedData)
  }

  const mergedGeneratedByLocationWasteSeries = React.useMemo(() => {
    return isBytype
      ? transformArrayByType(mergedGeneratedByLocationWaste as ApiTreatmentWaste[])
      : mergedGeneratedByLocationWaste
      ? transformArraydByLocation(mergedGeneratedByLocationWaste as ApiGeneratedByLocationAndLandfill[])
      : []
  }, [locations, data, wasteUnitBylocations, wasteUnit])

  const domain =
    rangeTo === rangeFrom && Number(rangeFrom) === dataAvailability?.planetCurrentFY
      ? getFinancialYearTimeRange(dataAvailability.planetCurrentFY).map(x => new Date(x))
      : domainFromSeries(mergedGeneratedByLocationWasteSeries)

  return isEqual(func, ['Tenants']) ? (
    <NoDataView text="Waste data is attributed to Common Areas" />
  ) : (
    <ChartContainer
      generator={lineChart}
      series={sortSeriesByBiggestContributor(mergedGeneratedByLocationWasteSeries as Serie[])}
      domain={domain}
      dateFormat={props.dateFormat}
      yAxisTitle={unitText}
      additionalComponents={additionalComponent}
      lineChartConfiguration={{
        focusStyle: dataKey === 'disposalTypeDescription' ? 'none' : 'nearest',
        startFromZero: false
      }}
      showDeselectAll
      deselectAllUnit={dataKey === 'disposalTypeDescription' ? 'treatment types' : undefined}
    />
  )
}

function domainFromSeries(series: Serie[]): Date[] {
  return chain(series)
    .flatMap(serie => serie.data)
    .map(d => d.x)
    .sort((a, b) => a.getTime() - b.getTime())
    .uniqBy(d => d.getTime())
    .value()
}

export const PlanetWasteByDisposalGraph: React.FC<Omit<PlanetWasteBaseGraphProps, 'dataKey' | 'colors'>> = props => {
  return PlanetWasteBaseGraph({ ...props, dataKey: 'disposalTypeDescription', colors: wasteDisposalColorMap })
}

const disposalText = (disposal: string, localisations: Localisation) => {
  switch (disposal) {
    case 'Material recycling':
      return localisations['Waste Types']['Material recycling']
    case 'External sorting facility':
      return localisations['Waste Types']['External sorting facility']
    case 'Incineration without energy recovery':
      return localisations['Waste Types']['Incineration without energy recovery']
    case 'Biogas':
      return localisations['Waste Types']['Biogas']
    case 'Incineration with energy recovery':
      return localisations['Waste Types']['Incineration with energy recovery']
    case 'IKEA Circular flows':
      return localisations['Waste Types']['IKEA Circular flows']
    case '(no-value)':
      return localisations['Waste Types']['(no value)']
    case 'Compost':
      return localisations['Waste Types']['Compost']
    case 'Landfill':
    default:
      return localisations['Waste Types']['Landfill']
  }
}

export const PlanetWasteByLocationGraph: React.FC<Omit<PlanetWasteBaseGraphProps, 'dataKey' | 'colors'>> = props => {
  const { mergedGeneratedByLocationWaste, siteOrCountry } = props
  const dataKey = siteOrCountry === 'ALL' || isCluster(siteOrCountry) ? 'countryCode' : 'siteId'
  const colors = colorScheme(uniq(mergedGeneratedByLocationWaste?.map(d => d[dataKey])))

  return PlanetWasteBaseGraph({ ...props, dataKey, colors })
}

function sortSeriesByBiggestContributor(series: Serie[]) {
  return series
    .sort((lhs, rhs) => {
      const lSum = lhs.data.reduce((sum, d) => sum + (d.y ?? 0), 0)
      const rSum = rhs.data.reduce((sum, d) => sum + (d.y ?? 0), 0)
      return rSum - lSum
    })
    .map((d, i) => ({ ...d, zIndex: i }))
}

const getWasteValue = (unit: GraphUnit, item: ApiGeneratedByLocationAndLandfill) => {
  switch (unit) {
    case 'rawwastekg':
      return item.recycled_waste_kg
    case 'recycledwaste':
      return item.recycling_share
    default:
      return item.landfill_share
  }
}

import React from 'react'
import { sumBy } from 'lodash'
import { WasteSelector, SelectorWithCountryCodes, getRecyclingRateTreatmentWaste } from '../../lib/APIClient'
import { formatAbsoluteNumber } from '../Utils/format'
import { getCurrentLocationLabel } from '../Utils/utils'
import { SlideSelectorWithCountryCodesProps } from './ClimateFootprintLargestContributorSlide'
import { InStoreSlide } from './InStoreSlide'
import { useDataAvailabilityContext, LanguageContext } from '../../context'
import { InStoreI18n } from '../../Localisation'
import { dataSourceText } from '../../Localisation'

import './WasteTreatmentSlide.scss'
import classNames from 'classnames'
import { fetchWasteData, PlanetWasteByDisposalGraph } from '../PlanetWasteGraphs'
import { ApiTreatmentWaste } from '../../../api/src/common-types'

interface WasteTreatmentByLocationSlideProps extends SlideSelectorWithCountryCodesProps {
  selectorLastFY: SelectorWithCountryCodes
  wasteSelector: WasteSelector
}

export const WasteTreatmentByLocationSlide: React.FC<WasteTreatmentByLocationSlideProps> = ({
  currentLocation,
  wasteSelector
}) => {
  const [wasteTotalThisFY, setWasteTotalThisFY] = React.useState(-1)
  const [wasteTotalLastFY, setWasteTotalLastFY] = React.useState(-1)
  const [wasteDataSource, setWasteDataSource] = React.useState('')
  const { dataAvailability } = useDataAvailabilityContext()
  const lang = React.useContext(LanguageContext)
  const i18n = InStoreI18n[lang]

  const currentLocationLabel = getCurrentLocationLabel(currentLocation, lang)

  const selectorCurrFY = { ...wasteSelector, start_fy: (dataAvailability?.planetCurrentFY || 25) - 2000 }
  const selectorLastFY = {
    ...wasteSelector,
    start_fy: (dataAvailability?.planetCurrentFY || 25) - 2000 - 1,
    end_fy: (dataAvailability?.planetCurrentFY || 25) - 2000 - 1
  }

  React.useEffect(() => {
    getRecyclingRateTreatmentWaste(selectorCurrFY).then(response => {
      const wastes = response
      setWasteTotalThisFY(sumBy(wastes, 'total'))
      if (wastes.length > 0) {
        setWasteDataSource(
          dataSourceText(lang, wastes[wastes.length - 1].readable_date, i18n.Generic.sustainData, dataAvailability)
        )

        const maxPeriodCurrentFY = Math.max(...wastes.map(({ period }) => period))
        getRecyclingRateTreatmentWaste(selectorLastFY).then(wastesLastFY => {
          setWasteTotalLastFY(
            sumBy(
              wastesLastFY.filter(({ period }) => period <= maxPeriodCurrentFY),
              'total'
            )
          )
        })
      }
    })
  }, [dataAvailability])

  return (
    <InStoreSlide
      className="WasteTreatmentSlide"
      header={{
        location: currentLocationLabel,
        description: i18n['Waste Treatment'].description,
        title: i18n['Waste Treatment'].title,
        dataSource: wasteDataSource
      }}
      series={{
        name: i18n['Waste Treatment'].totalYTD,
        value: formatAbsoluteNumber(Math.round(wasteTotalThisFY)),
        unit: 'kg',
        badge: `${formatAbsoluteNumber(Math.round(Math.abs(wasteTotalThisFY - wasteTotalLastFY)))} kg ${
          wasteTotalThisFY < wasteTotalLastFY ? i18n.Generic.less : i18n.Generic.more
        } ${i18n.Generic.thanThisTime}`,
        onTrack: wasteTotalThisFY - wasteTotalLastFY
      }}
      graph={<GraphContainer selector={wasteSelector} />}
      isLoading={wasteTotalThisFY === -1 || wasteTotalLastFY === -1}
    />
  )
}

interface GraphContainerProps {
  selector: WasteSelector
}

const GraphContainer = ({ selector }: GraphContainerProps) => {
  const [data, setData] = React.useState<ApiTreatmentWaste[]>()

  React.useEffect(() => {
    const getData = async () => {
      const data = await fetchWasteData(selector)
      setData(data as ApiTreatmentWaste[])
    }
    getData()
  }, [selector])

  return data ? (
    <div className={classNames('graphContainer', 'LessXAxisTicks')}>
      <PlanetWasteByDisposalGraph
        dateFormat="monthWithYear"
        mergedGeneratedByLocationWaste={data}
        siteOrCountry={selector.locationId}
        timeRange="monthly"
        isBytype
      />
    </div>
  ) : null
}

import { RecyclingRateBenchmark } from '../../../../../api/src/types/benchmark'
import { GoalsResponse } from '../../../../../api/src/types/goals'
import { getGoalsForLocation } from '../../../../components/Utils/utils'

export const mergeBenchmarkWithGoal = (
  benchmarks?: RecyclingRateBenchmark[] | undefined,
  goals?: GoalsResponse
): RecyclingRateBenchmark[] => {
  if (!goals) {
    return benchmarks || []
  }

  return (benchmarks || []).map(benchmark => {
    return {
      ...benchmark,
      goal: getGoalsForLocation(benchmark.id, goals)?.recyclingRateGoal ?? 0,
      goalNextFY: getGoalsForLocation(benchmark.id, goals)?.recyclingRateGoalNextFY ?? 0,
      goalPrevFY: getGoalsForLocation(benchmark.id, goals)?.recyclingRateGoalPrevFY ?? 0
    }
  })
}

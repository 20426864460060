import React, { createContext, useContext, useState } from 'react'
import { WasteFootprint } from '../../../../../api/src/types/climate'
import {
  getGoals,
  getInsights,
  getRecoveredProducts,
  getRecyclingRateBenchmark,
  getRecyclingRatePerformance,
  getWaste,
  SiteWithType
} from '../../../../lib/APIClient'
import {
  getCluster,
  getCountry,
  getLocationId,
  getLocationSelector,
  isCluster,
  isSiteId
} from '../../../../components/Utils/utils'
import { CountryCode } from '../../../../../api/src/types/location'
import { format } from 'date-fns'
import { RecoveredProducts } from '../../../../../api/src/types/zero-waste'
import { RecyclingRatePerformanceType } from '../../../../../api/src/types/performance'
import { RecyclingRateBenchmark } from '../../../../../api/src/types/benchmark'
import { useDataAvailabilityContext, useLocations } from '../../../../context'
import { useSharedSelections } from '../../../../SharedSelections'
import { GoalsResponse } from '../../../../../api/src/types/goals'
import { getIngkaFinancialYear } from '../../../../components/Utils/dates'
import { KpiModalState } from '../../../../components/Modal'
import { Insight } from '../../../../../api/src/types/insight'

interface DataContextType {
  wasteData: WasteFootprint[] | undefined
  recoveredProducts: RecoveredProducts[] | undefined
  performance: RecyclingRatePerformanceType | undefined
  benchmarking: RecyclingRateBenchmark[] | undefined
  domain: Date[]
  lastUpdated: string
  goals: GoalsResponse | undefined
  modalState: KpiModalState
  setModalState: React.Dispatch<React.SetStateAction<KpiModalState>>
  benchmarkModalOpen: boolean
  setBenchmarkModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  insights: Insight[] | null
}

const DataContext = createContext<DataContextType | undefined>(undefined)

export const DataProvider: React.FC<React.PropsWithChildren<Record<never, never>>> = ({ children }) => {
  const [wasteData, setWasteData] = useState<WasteFootprint[] | undefined>(undefined)
  const [recoveredProducts, setRecoveredProducts] = React.useState<RecoveredProducts[] | undefined>(undefined)
  const [performance, setPerformance] = React.useState<RecyclingRatePerformanceType | undefined>(undefined)
  const [benchmarking, setBenchmarking] = React.useState<RecyclingRateBenchmark[] | undefined>(undefined)
  const [domain, setDomain] = React.useState<Date[]>([])
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [goals, setGoals] = React.useState<GoalsResponse>()
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [insights, setInsights] = React.useState<Insight[] | null>(null)

  const { currentLocation, clusters, locations } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const [{ func }] = useSharedSelections()
  const locationId = getLocationId(currentLocation)

  // Get fiscal year from data availability or current year
  const goalsFy = dataAvailability?.recyclingCurrentFY ?? getIngkaFinancialYear(new Date()).getFullYear()

  React.useEffect(() => {
    if (locations.length === 0) {
      return
    }
    setRecoveredProducts(undefined)
    setPerformance(undefined)
    setBenchmarking(undefined)
    setDomain([])
    setWasteData(undefined)
    setGoals(undefined)
    setInsights(null)

    const baseSelector = {
      start_fy: dataAvailability?.planetPreviousFY,
      end_fy: dataAvailability?.planetCurrentFY,
      func: func,
      isOld: true
    }

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getRecyclingRatePerformance(
      func,
      dataAvailability?.planetCurrentFY ?? 2025,
      countryCode,
      currentLocation.isCluster ? '' : (currentLocation.location as SiteWithType).siteName
    ).then(performance => setPerformance(performance))

    getRecyclingRateBenchmark(countryCode, func).then(items => setBenchmarking(items))

    getWaste(locationId, func, goalsFy).then(response => {
      setDomain(response.dates.map(d => new Date(d)))
      setWasteData(response.data)
      response.lastUpdated && setLastUpdated(format(new Date(response.lastUpdated), 'dd/MM/yyyy'))
    })

    getRecoveredProducts({
      ...getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes),
      ...baseSelector
    }).then(setRecoveredProducts)

    if (!currentLocation.isCluster) {
      getInsights({
        locationId,
        func
      }).then(result => setInsights(result.filter(({ type }) => type === 'zero-waste')))
    } else {
      setInsights([])
    }

    if (!isCluster(locationId)) {
      getGoals(getCountry(locationId, locations).countryCode, func, goalsFy).then(setGoals)
    }
  }, [getLocationId(currentLocation), JSON.stringify(func), locations.length])

  return (
    <DataContext.Provider
      value={{
        wasteData,
        recoveredProducts,
        performance,
        benchmarking,
        domain,
        lastUpdated,
        goals,
        modalState,
        setModalState,
        benchmarkModalOpen,
        setBenchmarkModalOpen,
        insights
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export const useDataContext = (): DataContextType => {
  const context = useContext(DataContext)
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider')
  }
  return context
}

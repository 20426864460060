import React from 'react'
import { TopBar } from '../../../../components/TopBar'
import { PageHeader } from '../../../../components/PageHeader'
import { Route } from '../../../../routes'
import { DataSourceAndModalButton, Stripe } from '../../../../components/KPIPage'
import { Link } from '../../../../components/Link'
import Button from '@ingka/button'
import { useDataContext } from './DataProvider'
import { KpiPageLearnMoreModal } from '../../../../components/Modal'

export const PageHead: React.FC<React.PropsWithChildren<Record<never, never>>> = props => {
  const { lastUpdated, modalState, setModalState } = useDataContext()

  const page = Route.ZeroWasteKPIPage

  const exploreButton = (
    <Link page={Route.ZeroWasteExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader className="ZeroWasteHeader" route={Route.ZeroWasteKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Recycling Rate">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        {props.children}
        <Stripe
          title="Want to learn more about Recycling Rate?"
          subtitle="Explore contains additional charts and time selections."
        >
          {exploreButton}
        </Stripe>
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
    </div>
  )
}

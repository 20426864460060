import React from 'react'
import {
  ApiGeneratedByLocation,
  ApiNonRecycledAndGeneratedByType,
  ApiTreatmentMap,
  ApiTreatmentWaste
} from '../../../../../api/src/common-types'
import { TimeRange } from '../../ExplorePage'
import { GraphUnit } from '../../../../components/UnitSelector'
import { getNonRecycledAndGeneratedByTypeSeries } from '../utils'
import { PlanetWasteMapBase, wasteGroupColorMap } from '../../../../components/PlanetWasteMap'
import { ExploreGraphCard } from '../../ExploreGraphCard'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../../components/Utils/format'
import { sumBy } from 'lodash'
import { WasteByTypeGraph } from '../../Climate/ClimateExplorePage'
import { LoadingSkeleton } from '../../../../components/LoadingSkeleton'
import { PlanetWasteByDisposalGraph, PlanetWasteByLocationGraph } from '../../../../components/PlanetWasteGraphs'

interface WasteGraphsProps {
  dates?: Date[]
  locationId: string
  timeRange: TimeRange
  mergedGeneratedByLocationWaste?: ApiGeneratedByLocation[]
  generatedByTypeWaste?: ApiNonRecycledAndGeneratedByType[]
  treatmentWaste?: ApiTreatmentWaste[]
  treatmentMap?: ApiTreatmentMap[]
  isClimatExplore?: boolean
}

export const WasteGraphs: React.FC<WasteGraphsProps> = ({
  dates,
  locationId,
  timeRange,
  mergedGeneratedByLocationWaste,
  generatedByTypeWaste,
  treatmentWaste,
  treatmentMap,
  isClimatExplore
}) => {
  const [byTypeUnit, setByTypeUnit] = React.useState(GraphUnit.RawWaste)
  const [selectedUnit, setSelectedUnit] = React.useState<string>('kg')
  const isMonthly = timeRange === 'monthly'
  const isFootprint = byTypeUnit !== 'rawwaste'

  const generatedByTypeWasteSeries = getNonRecycledAndGeneratedByTypeSeries(
    generatedByTypeWaste,
    wasteGroupColorMap,
    isMonthly,
    isFootprint
  )

  const currentUnitByType = (unit: string) => setSelectedUnit(unit)

  const currentUnit = byTypeUnit === 'rawwaste' ? 'tonnes' : 'tonnes (CO2e)'

  const avgRecyclingRate =
    mergedGeneratedByLocationWaste &&
    mergedGeneratedByLocationWaste?.reduce((acc, item) => acc + item.recycling_share, 0) /
      mergedGeneratedByLocationWaste.length

  const avgRecycledWaste =
    mergedGeneratedByLocationWaste &&
    mergedGeneratedByLocationWaste?.reduce((acc, item) => acc + item.recycled_waste_kg, 0) /
      mergedGeneratedByLocationWaste.length

  return (
    <>
      <ExploreGraphCard
        heading="Waste Generation by Type"
        description={
          isClimatExplore
            ? 'This visualization shows how much waste this location produces by material type over time.'
            : undefined
        }
        amount={
          generatedByTypeWaste
            ? currentUnit === 'tonnes'
              ? formatAbsoluteNumber(Math.round(sumBy(generatedByTypeWaste, 'waste')))
              : formatAbsoluteNumber(Math.round(sumBy(generatedByTypeWaste, 'footprint')))
            : 'N/A'
        }
        unit={currentUnit === 'tonnes' ? currentUnit : 'tonnes CO2e total'}
      >
        {generatedByTypeWaste ? (
          <WasteByTypeGraph
            dateFormat={timeRange === 'monthly' ? 'monthWithYear' : 'fy'}
            domain={dates}
            series={generatedByTypeWasteSeries}
            unit={byTypeUnit}
            unitDescription={currentUnit}
            onUnitChange={setByTypeUnit}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Waste Generation by Location"
        amount={
          isClimatExplore
            ? avgRecycledWaste
              ? formatAbsoluteNumber(avgRecycledWaste)
              : undefined
            : mergedGeneratedByLocationWaste && selectedUnit === GraphUnit.RawWasteKg
            ? formatAbsoluteNumber(sumBy(generatedByTypeWaste, 'footprint') * 1000)
            : ''
        }
        unit={isClimatExplore ? 'kg avg' : selectedUnit === GraphUnit.RawWasteKg ? 'kg CO2e total' : ''}
      >
        {mergedGeneratedByLocationWaste ? (
          isClimatExplore ? (
            <PlanetWasteByLocationGraph
              siteOrCountry={locationId}
              dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
              // selactedUnit={currentUnitByType}
              mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
              timeRange={timeRange}
              isClimatExplore={isClimatExplore}
            />
          ) : (
            <PlanetWasteByLocationGraph
              siteOrCountry={locationId}
              dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
              selactedUnit={currentUnitByType}
              mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
              timeRange={timeRange}
            />
          )
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      <ExploreGraphCard
        heading="Waste Treatment by Type"
        amount={generatedByTypeWaste ? formatAbsoluteNumber(Math.round(sumBy(treatmentWaste, 'total'))) : 'N/A'}
        unit="kg"
        description={
          isClimatExplore
            ? 'This visualization shows how this location has disposed of its waste over time.'
            : undefined
        }
      >
        {generatedByTypeWaste ? (
          <PlanetWasteByDisposalGraph
            siteOrCountry={locationId}
            dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
            mergedGeneratedByLocationWaste={treatmentWaste}
            isBytype
            timeRange={timeRange}
          />
        ) : (
          <LoadingSkeleton />
        )}
      </ExploreGraphCard>
      {isClimatExplore ? (
        <ExploreGraphCard
          heading="Recycling Rate"
          amount={avgRecyclingRate ? formatRelativeNumber(avgRecyclingRate) : undefined}
          unit="% avg"
          description="% of waste sent for “Material recycling”, “Compost”, “Biogas” or “IKEA circular flows”"
        >
          {mergedGeneratedByLocationWaste ? (
            <PlanetWasteByLocationGraph
              siteOrCountry={locationId}
              dateFormat={timeRange === 'annual' ? 'fy' : 'monthWithYear'}
              mergedGeneratedByLocationWaste={mergedGeneratedByLocationWaste}
              timeRange={timeRange}
              isClimatExploreRecyclingRate={true}
            />
          ) : (
            <LoadingSkeleton />
          )}
        </ExploreGraphCard>
      ) : null}
      <ExploreGraphCard
        heading="Waste Material to Treatment Map"
        amount={treatmentMap ? formatAbsoluteNumber(Math.round(sumBy(treatmentMap, 'raw'))) : 'N/A'}
        unit="kg"
        description={
          isClimatExplore
            ? 'A visualization that shows the streams of waste coming out of a location. On the left are kinds of materials. On the right are methods of disposal. The thickness of a line connecting left to right shows how much material is disposed of through the method on the right.'
            : undefined
        }
      >
        <PlanetWasteMapBase data={treatmentMap} />
      </ExploreGraphCard>
    </>
  )
}
